<template>
  <section class="relative w-full flex justify-center items-center z-50">
    <div class="w-[1024px] lg:mx-8 min-h-screen">
      <Spinner :is-visible="isFetching" />
      <title-bar :title="currentTitle" />
      <div class="flex flex-row justify-between">
        <back-button v-if="!showBooks" @back="handleBack" class="m-4" />
        <report-button v-if="isVideoVisible && showReportButton" class="m-4" />
      </div>
      <QuestionTitleBar v-if="isVideoVisible" :title="currentQuestionTitle" :videoUrl="selectedVideoUrl"
        :questionNumber="currentQuestionIndex + 1" :answer="currentAnswer" :showAnswer="showAnswer"
        class="self-center" />
      <BackToTopButton />
      <div v-if="errorMessage && !isVideoVisible" class="flex items-center justify-center">
        <div
          class="text-red-500 bg-red-200 rounded-lg font-semibold py-2 px-4 flex flex-row text-nowrap items-center justify-center">
          <img src="@/assets/error-icon.svg" class="mr-2" /> {{ errorMessage }}
        </div>
      </div>
      <div v-else>
        <div class="container flex flex-col justify-end items-end">
          <video-player v-if="isVideoVisible && !errorMessage" :src="selectedVideoUrl" @close="closeVideoPlayer"
            class="w-full" />
          <VideoDownloadButton v-if="isVideoVisible && !errorMessage" :videoUrl="selectedVideoUrl"
            :questionNumber="currentQuestionIndex + 1" class="mt-4 mr-4 lg:mr-0" />
        </div>
        <div v-if="errorMessage && isVideoVisible" class="flex items-center justify-center mt-4 h-[384px]">
          <div
            class="text-red-500 bg-red-200 rounded-lg font-semibold py-2 px-4 flex flex-row text-nowrap items-center justify-center">
            <img src="@/assets/error-icon.svg" class="mr-2" />
            {{ errorMessage }}
          </div>
        </div>
        <div class="relative">
          <Spinner :is-visible="isFetching" />
          <ReportNotices v-if="isVideoVisible && showReportNotices && showReports" :reports="reports" />
        </div>
        <!-- Books -->
        <div v-if="showBooks">
          <div v-for="book in books" :key="book.id" @click="showBookSections(book)"
            class="m-4 md:px-8 px-6 py-3 rounded-3xl cursor-pointer flex flex-col justify-between hover:scale-105 duration-500 transition-all bg-gradient-to-r to-blue-500 via-gray-900 from-gray-900 bg-size-200 bg-pos-0 hover:bg-pos-100">
            <div
              class="text-md md:text-xl font-medium text-white flex justify-between items-center md:gap-4 gap-2 relative">
              <div class="flex flex-row items-center md:gap-4 gap-2">
                <img src="@/assets/book.svg" alt="Book Icon" />
                <span>{{ book.book_name }}</span>
              </div>
              <img class="min-w-8 min-h-8 h-8 w-8 absolute right-0 top-5" src="@/assets/arrow-right-2.svg"
                alt="Arrow Icon" />
            </div>
            <div v-if="showQuestionCount" class="mt-2">
              <span class="text-gray-500"> Toplam Soru Sayısı: </span>
              <span class="text-orange-500">{{ book.totalQuestions }}</span>
            </div>
          </div>
        </div>
        <!-- Sections -->
        <div v-if="showSections">
          <div v-for="section in sections" :key="section.id" @click="showBookTests(section)"
            class="m-4 md:px-8 px-6 py-3 rounded-3xl cursor-pointer flex flex-col justify-between hover:scale-105 duration-500 transition-all bg-gradient-to-r to-blue-500 via-slate-900 from-slate-900 bg-size-200 bg-pos-0 hover:bg-pos-100">
            <div
              class="text-md md:text-xl font-medium text-white flex justify-between items-center md:gap-4 gap-2 relative">
              <div class="flex flex-row items-center md:gap-4 gap-2">
                <img src="@/assets/book.svg" alt="Book Icon" />
                <span>{{ section.title }}</span>
              </div>
              <img class="min-w-8 min-h-8 h-8 w-8 absolute right-0 top-5" src="@/assets/arrow-right-2.svg"
                alt="Arrow Icon" />
            </div>
            <div v-if="showQuestionCount" class="mt-2">
              <span class="text-gray-500"> Toplam Soru Sayısı: </span>
              <span class="text-orange-500">{{ section.totalQuestions }}</span>
            </div>
          </div>
        </div>
        <!-- Tests -->
        <div v-if="showTests">
          <div v-for="test in tests" :key="test.id" @click="showBookQuestions(test)"
            class="m-4 md:px-8 px-6 py-3 rounded-3xl cursor-pointer flex flex-col justify-between hover:scale-105 duration-500 transition-all bg-gradient-to-r to-blue-500 via-slate-900 from-slate-900 bg-size-200 bg-pos-0 hover:bg-pos-100">
            <div
              class="text-md md:text-xl font-medium text-white flex justify-between items-center md:gap-4 gap-2 relative">
              <div class="flex flex-row items-center md:gap-4 gap-2">
                <img src="@/assets/book.svg" alt="Book Icon" />
                <span>{{ test.title }}</span>
              </div>
              <img class="min-w-8 md:min-h-8 h-8 w-8 absolute right-0 top-5" src="@/assets/arrow-right-2.svg"
                alt="Arrow Icon" />
            </div>
            <div v-if="showQuestionCount" class="mt-2 flex flex-col md:flex-row md:divide-x divide-gray-500">
              <div>
                <span class="text-gray-500"> Toplam Soru Sayısı: </span>
                <span class="text-orange-500">{{ test.totalQuestions }}</span>
              </div>
              <div class="md:ml-2 md:pl-2">
                <span class="text-gray-500"> Çözümlü Soru Sayısı: </span>
                <span class="text-orange-500">{{
                  test.totalRecordedQuestions
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Questions -->
        <!-- Questions -->
        <div v-if="showQuestions && questions.length > 0">
          <div v-for="question in questions" :key="question.id" @click="
            handleQuestionClick(question)
            " :class="[
              'm-4 px-4 py-3 h-40 rounded-3xl flex justify-between items-center transition-all bg-gradient-to-r to-blue-500 via-slate-900 from-slate-900 bg-size-200 bg-pos-0 hover:bg-pos-100',
              {
                'hover:scale-105 cursor-pointer': question.video_solution,
                'opacity-50 cursor-not-allowed': !question.video_solution,
              },
            ]">
            <div class="md:ml-4 ml-2 text-sm md:text-xl font-medium text-white flex items-center md:gap-4 gap-2">
              <img src="@/assets/book.svg" alt="Book Icon" />
              <span>{{ question.order_number }}. Soru</span>
            </div>
            <div class="flex items-center justify-center min-w-24 min-h-24 max-w-36 max-h-36">
              <img class="max-w-36 max-h-36 p-2 mr-14 md:mr-0" v-bind:src="question.question_image" />
            </div>
            <div class="md:min-w-[120px] flex justify-center">
              <img class="min-w-8 min-h-8 h-8 w-8" src="@/assets/play-icon.svg" alt="Play Icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>



<script setup>
import { ref, onMounted, watch, provide } from "vue";
import { useStore } from "vuex";
import api from "@/services/api";
import VideoPlayer from "./VideoPlayer.vue";
import TitleBar from "./TitleBar.vue";
import BackButton from "./BackButton.vue";
import Spinner from "./Spinner.vue";
import QuestionTitleBar from "./QuestionTitleBar.vue";
import ReportButton from "./ReportButton.vue";
import VideoDownloadButton from "./VideoDownloadButton.vue";
import ReportNotices from "./ReportNotices.vue";
import BackToTopButton from "./BackToTopButton.vue"

const store = useStore();
const tests = ref([]);
const books = ref([]);
const sections = ref([]);
const questions = ref([]);
const reports = ref([]);
const errorMessage = ref("");
const showBooks = ref(true);
const showTests = ref(false);
const showSections = ref(false);
const showQuestions = ref(false);
const isFetching = ref(false);
const currentBookTitle = ref("Kaynaklar");
const currentSectionTitle = ref("");
const currentTestTitle = ref("");
const currentTitle = ref(currentBookTitle.value);
const isVideoVisible = ref(false);
const selectedVideoUrl = ref("");
const currentQuestionIndex = ref(store.getters.currentQuestionIndex);
const currentQuestionTitle = ref("");
const currentAnswer = ref("");
const showAnswer = ref(false);
const showQuestionCount = process.env.VUE_APP_BOOK_LIST_SHOW_QUESTION_COUNT === "true";
const showReportButton = process.env.VUE_APP_SHOW_REPORT_BUTTON === "true";
const showReportNotices = process.env.VUE_APP_SHOW_REPORT_NOTICES === "true";
const showReports = ref(false);

provide("nextQuestion", async () => {
  if (currentQuestionIndex.value < questions.value.length - 1) {
    currentQuestionIndex.value++;
    store.dispatch("setCurrentQuestionIndex", currentQuestionIndex.value);
    const nextQuestion = questions.value[currentQuestionIndex.value];
    if (nextQuestion.video_solution) {
      await openVideoPlayer(
        nextQuestion.video_solution,
        currentQuestionIndex.value + 1,
        `${nextQuestion.order_number}. Soru` || "Belirsiz Soru",
        nextQuestion.id,
        nextQuestion.question_image // Burada resim URL'sini ekleyin
      );
    } else {
      currentQuestionTitle.value =
        `${nextQuestion.order_number}. Soru` || "Belirsiz Soru";
      errorMessage.value = `${nextQuestion.order_number}. soru için çözüm videosu bulunamadı.`;
      isVideoVisible.value = true;
      showQuestions.value = false;
      showAnswer.value = false;
      showReports.value = false;
      updateTitle();
    }
  }
});

provide("prevQuestion", async () => {
  if (currentQuestionIndex.value > 0) {
    currentQuestionIndex.value--;
    store.dispatch("setCurrentQuestionIndex", currentQuestionIndex.value);
    const prevQuestion = questions.value[currentQuestionIndex.value];
    if (prevQuestion.video_solution) {
      await openVideoPlayer(
        prevQuestion.video_solution,
        currentQuestionIndex.value + 1,
        `${prevQuestion.order_number}. Soru` || "Belirsiz Soru",
        prevQuestion.id,
        prevQuestion.question_image // Burada resim URL'sini ekleyin
      );
    } else {
      currentQuestionTitle.value =
        `${prevQuestion.order_number}. Soru` || "Belirsiz Soru";
      errorMessage.value = `${prevQuestion.order_number}. soru için çözüm videosu bulunamadı.`;
      isVideoVisible.value = true;
      showQuestions.value = false;
      showAnswer.value = false;
      showReports.value = false;
      updateTitle();
    }
  }
});

provide("questions", questions);
provide("currentQuestionIndex", currentQuestionIndex);

const updateTitle = () => {
  if (showBooks.value) {
    currentTitle.value = "Kaynaklar";
  } else if (showSections.value) {
    currentTitle.value = `${currentBookTitle.value}`;
  } else if (showTests.value) {
    currentTitle.value = `${currentBookTitle.value} / ${currentSectionTitle.value}`;
  } else if (showQuestions.value && !isVideoVisible.value) {
    currentTitle.value = `${currentBookTitle.value} / ${currentSectionTitle.value} / ${currentTestTitle.value}`;
  } else if (isVideoVisible.value) {
    currentTitle.value = `${currentBookTitle.value} / ${currentSectionTitle.value} / ${currentTestTitle.value}`;
  }
  store.dispatch("setCurrentBookTitle", currentBookTitle.value);
  store.dispatch("setCurrentSectionTitle", currentSectionTitle.value);
  store.dispatch("setCurrentTestTitle", currentTestTitle.value);
  store.dispatch("setCurrentQuestionTitle", currentQuestionTitle.value);
};

const handleBack = () => {
  if (errorMessage.value) {
    errorMessage.value = "";
    isVideoVisible.value = false;
    showQuestions.value = true;
    updateTitle();
  } else if (isVideoVisible.value) {
    isVideoVisible.value = false;
    showQuestions.value = true;
    currentQuestionTitle.value = "";
    updateTitle();
  } else if (showQuestions.value) {
    showQuestions.value = false;
    showTests.value = true;
    currentQuestionIndex.value = 0;
    updateTitle();
  } else if (showTests.value) {
    showTests.value = false;
    showSections.value = true;
    currentTestTitle.value = "";
    updateTitle();
  } else if (showSections.value) {
    showSections.value = false;
    showBooks.value = true;
    currentBookTitle.value = "Kaynaklar";
    updateTitle();
  }
};


watch(
  [
    showBooks,
    showSections,
    showTests,
    showQuestions,
    isVideoVisible,
    currentQuestionIndex,
  ],
  updateTitle
);

const getBooks = async () => {
  try {
    isFetching.value = true;
    const response = await api.get(`${process.env.VUE_APP_API_URL}my-tasks-v3`);
    books.value = response.data.data.map((book) => {
      const totalQuestions = book.sections.reduce(
        (sum, section) => sum + section.total_number_of_questions,
        0
      );
      return { ...book, totalQuestions };
    });
  } catch (error) {
    console.error("Kitaplar getirilirken hata oluştu:", error);
    errorMessage.value = "Kitaplar getirilirken hata oluştu.";
  } finally {
    isFetching.value = false;
  }
};

const showBookSections = async (book) => {
  isFetching.value = true;
  sections.value =
    book.sections.map((section) => {
      const totalQuestions = section.total_number_of_questions || 0;
      return { ...section, totalQuestions };
    }) || [];
  showSections.value = true;
  showBooks.value = false;
  currentBookTitle.value = book.book_name;
  currentSectionTitle.value = "";
  currentTestTitle.value = "";
  currentQuestionIndex.value = 0;
  updateTitle();
  isFetching.value = false;
};

const showBookTests = async (section) => {
  const url = `my-task-section/${section.id}`;
  try {
    isFetching.value = true;
    const response = await api.get(url);
    tests.value =
      response.data.data.tests.map((test) => {
        const totalQuestions = test.total_number_of_questions || 0;
        const totalRecordedQuestions = test.total_recorded_questions || 0;
        return { ...test, totalQuestions, totalRecordedQuestions };
      }) || [];
    showSections.value = false;
    showTests.value = true;
    currentSectionTitle.value = section.title;
    currentTestTitle.value = "";
    currentQuestionIndex.value = 0;
    updateTitle();
  } catch (error) {
    console.error("Testler getirilirken hata oluştu:", error);
    errorMessage.value = "Testler getirilirken hata oluştu.";
  } finally {
    isFetching.value = false;
  }
};

const showBookQuestions = async (test) => {
  const url = `my-task-test/${test.id}`;

  try {
    isFetching.value = true;

    const response = await api.get(url);
    if (
      response.data.data.question_tasks &&
      response.data.data.question_tasks.length > 0
    ) {
      questions.value = response.data.data.question_tasks.map(
        (question, index) => {
          console.log('Question data:', question); // API'den gelen soru verisini kontrol edin
          return {
            ...question,
            order_number: index + 1,
            question_image: question.question_image // Ensure question_image is included
          };
        }
      );
      showTests.value = false;
      showQuestions.value = true;
      currentTestTitle.value = test.title;
      currentQuestionIndex.value = 0;
      store.dispatch("setCurrentQuestionIndex", currentQuestionIndex.value); // Store'a kaydet
      errorMessage.value = "";
      updateTitle();
      await fetchReportsAndAnswer(); // Hata bildirimlerini ve answer'ı fetch et
    } else {
      questions.value = [];
      showTests.value = true;
      showQuestions.value = false;
      currentTestTitle.value = test.title;
      errorMessage.value = "Bu testte hiç soru bulunamadı!";
      updateTitle();
    }
  } catch (error) {
    console.error("Sorular getirilirken hata oluştu:", error);
    errorMessage.value = "Sorular getirilirken hata oluştu.";
  } finally {
    isFetching.value = false;
  }
};



const openVideoPlayer = async (
  videoUrl,
  questionIndex,
  questionTitle,
  questionId,
  questionImage // Resim parametresi eklendi
) => {
  console.log("openVideoPlayer called with:");
  console.log("videoUrl:", videoUrl);
  console.log("questionIndex:", questionIndex);
  console.log("questionTitle:", questionTitle);
  console.log("questionId:", questionId);
  console.log("questionImage:", questionImage);

  if (!videoUrl) return;

  currentQuestionIndex.value = questionIndex - 1;
  store.dispatch("setCurrentQuestionIndex", currentQuestionIndex.value);
  store.dispatch("setCurrentQuestionId", questionId);
  store.dispatch("setCurrentQuestionImage", questionImage); // Soru resmini store'a kaydet

  console.log("Store state after dispatch:");
  console.log("currentQuestionImage:", store.getters.currentQuestionImage);

  currentQuestionTitle.value = questionTitle
    ? `${questionIndex}. Soru`
    : "Belirsiz Soru";
  selectedVideoUrl.value = videoUrl;
  errorMessage.value = "";
  isVideoVisible.value = true;
  showQuestions.value = false;
  await fetchReportsAndAnswer();
  updateTitle();
};


const handleQuestionClick = async (question) => {
  if (question.video_solution) {
    await openVideoPlayer(
      question.video_solution,
      question.order_number,
      question.question_order_number ? `${question.order_number}. Soru` : 'Belirsiz Soru',
      question.id,
      question.question_image // Burada question_image değerini geçiyoruz
    );
  }
};




const closeVideoPlayer = () => {
  isVideoVisible.value = false;
  currentQuestionTitle.value = "";
  showQuestions.value = true;
  updateTitle();
};


const fetchReportsAndAnswer = async () => {
  const currentQuestion = questions.value[currentQuestionIndex.value];
  if (!currentQuestion) return;
  const newApiUrl = `my-task-question/${currentQuestion.id}`;

  try {
    isFetching.value = true;
    const newApiResponse = await api.get(newApiUrl);
    reports.value = newApiResponse.data.data.error_reports;
    currentAnswer.value = newApiResponse.data.data.answer;
    showAnswer.value = true;
    showReports.value = !!newApiResponse.data.data.video_solution;
  } catch (error) {
    console.error(
      "Hata bildirimleri ve cevap getirilirken hata oluştu:",
      error
    );
  } finally {
    isFetching.value = false;
  }
};

onMounted(async () => {
  await getBooks();
});

</script>

<style scoped></style>