<template>
  <div class="flex justify-center items-center w-full">
    <span
      class="px-4 sm:px-6 md:px-8 lg:px-12 py-2 m-4 text-gray-700 text-center font-semibold text-xl sm:text-xl md:text-2xl">
      {{ title }}
    </span>
  </div>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
