import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/Login.vue";
import List from "@/views/List.vue";
import Form from "@/views/Form.vue";
import Reports from "@/views/Reports.vue";
import store from "@/store";

const routes = [
  { path: "/", redirect: "/login" },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresGuest: true,
      title: "Giriş - indiviBook Video Çözüm Portalı",
    },
  },
  {
    path: "/list",
    name: "List",
    component: List,
    meta: {
      requiresAuth: true,
      title: "Kaynaklar - indiviBook Video Çözüm Portalı",
    },
  },
  {
    path: "/report",
    name: "Report",
    component: Form,
    meta: {
      requiresAuth: true,
      title: "Sorun Bildir - indiviBook Video Çözüm Portalı",
    },
  },
  {
    path: "/error-reports",
    name: "Error Reports",
    component: Reports,
    meta: {
      requiresAuth: true,
      title: "Sorun Bildir - indiviBook Video Çözüm Portalı",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next({ name: "Login" });
  } else if (
    to.matched.some((record) => record.meta.requiresGuest) &&
    isAuthenticated
  ) {
    next({ name: "List" });
  } else {
    next();
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "indiviBook Video Çözüm Portalı";
  }
});

export default router;
