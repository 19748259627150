import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    token: localStorage.getItem("token") || "",
    currentQuestionId: null,
    currentBookTitle: "",
    currentSectionTitle: "",
    currentTestTitle: "",
    currentQuestionTitle: "",
    currentQuestionImage: "",
    books: [],
    questions: [],
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    clearToken(state) {
      state.token = "";
      localStorage.removeItem("token");
    },
    setCurrentQuestionId(state, id) {
      state.currentQuestionId = id;
    },
    setCurrentBookTitle(state, title) {
      state.currentBookTitle = title;
    },
    setCurrentSectionTitle(state, title) {
      state.currentSectionTitle = title;
    },
    setCurrentTestTitle(state, title) {
      state.currentTestTitle = title;
    },
    setCurrentQuestionTitle(state, title) {
      state.currentQuestionTitle = title;
    },
    setCurrentQuestionImage(state, imageUrl) {
      state.currentQuestionImage = imageUrl;
    },
    setBooks(state, books) {
      state.books = books;
    },
    setQuestions(state, questions) {
      state.questions = questions;
    },
  },
  actions: {
    login({ commit }, token) {
      commit("setToken", token);
    },
    logout({ commit }) {
      commit("clearToken");
    },
    setCurrentQuestionId({ commit }, id) {
      commit("setCurrentQuestionId", id);
    },
    setCurrentBookTitle({ commit }, title) {
      commit("setCurrentBookTitle", title);
    },
    setCurrentSectionTitle({ commit }, title) {
      commit("setCurrentSectionTitle", title);
    },
    setCurrentTestTitle({ commit }, title) {
      commit("setCurrentTestTitle", title);
    },
    setCurrentQuestionTitle({ commit }, title) {
      commit("setCurrentQuestionTitle", title);
    },
    setCurrentQuestionImage({ commit }, imageUrl) {
      commit("setCurrentQuestionImage", imageUrl);
    },
    async fetchBooks({ commit, state }) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}my-tasks-v3`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        commit("setBooks", response.data.data);
      } catch (error) {
        console.error("Kitaplar getirilirken hata oluştu:", error);
      }
    },
    async fetchSections({ state }, bookId) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}my-task-sections/${bookId}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        return response.data.data.sections;
      } catch (error) {
        console.error(`Bölümler getirilirken hata oluştu: ${bookId}`, error);
        return [];
      }
    },
    async fetchTests({ state }, sectionId) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}my-task-section/${sectionId}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        return response.data.data.tests;
      } catch (error) {
        console.error(`Testler getirilirken hata oluştu: ${sectionId}`, error);
        return [];
      }
    },
    async fetchQuestions({ state }, testId) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}my-task-test/${testId}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        return response.data.data.question_tasks;
      } catch (error) {
        console.error(`Sorular getirilirken hata oluştu: ${testId}`, error);
        return [];
      }
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    token: (state) => state.token,
    currentQuestionId: (state) => state.currentQuestionId,
    currentBookTitle: (state) => state.currentBookTitle,
    currentSectionTitle: (state) => state.currentSectionTitle,
    currentTestTitle: (state) => state.currentTestTitle,
    currentQuestionTitle: (state) => state.currentQuestionTitle,
    currentQuestionImage: (state) => {
      console.log(
        "Getter currentQuestionImage called. Value:",
        state.currentQuestionImage
      );
      return state.currentQuestionImage;
    },
  },
});
