<template>
  <button
    class="flex flex-row items-center justify-center bg-blue-700 hover:bg-blue-600 text-white py-2 px-4 ml-4 mb-4 rounded hover:scale-105 duration-300"
    @click="downloadVideo"
  >
    <img src="@/assets/download-icon.svg" class="mr-2" />Video indir
  </button>
</template>

<script>
export default {
  name: "DownloadButton",
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    questionNumber: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async downloadVideo() {
      try {
        const response = await fetch(this.videoUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.getFileName(this.questionNumber));
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error("Video indirilemedi:", error);
      }
    },
    getFileName(questionNumber) {
      return `${questionNumber}`;
    },
  },
};
</script>

<style scoped></style>
