<template>
  <button @click="nextQuestion" :disabled="!questions || currentQuestionIndex === questions.length - 1"
    class="text-sm flex flex-row text-nowrap items-center justify-center px-4 py-2 bg-slate-900 text-white rounded disabled:opacity-50 duration-300 enabled:hover:bg-slate-800">
    <img src="@/assets/question-arrow-right.svg" class="order-2 ml-2" />Sonraki
    soru
  </button>
</template>

<script setup>
import { inject } from "vue";

const nextQuestion = inject("nextQuestion");
const questions = inject("questions", []);
const currentQuestionIndex = inject("currentQuestionIndex", 0);
</script>

<style scoped></style>
