<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50 z-50"
  >
    <span class="text-white mb-4">Yükleniyor</span>
    <div
      class="spinner border-4 border-blue-700 w-16 h-16 rounded-full border-l-white animate-spin"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
  },
};
</script>

<style scoped></style>
