<template>
    <div class="my-8 flex items-center justify-center min-h-screen relative z-50">
        <div class="relative rounded-lg w-full lg:w-[1024px] min-h-screen overflow-x-auto mx-4 lg:mx-8 p-4">
            <table class="w-full text-sm text-left text-gray-500">
                <thead class="text-xs text-gray-400 uppercase bg-slate-900">
                    <tr>
                        <th scope="col" class="px-4 sm:px-6 py-3 rounded-tl-lg">Künye</th>
                        <th scope="col" class="px-4 sm:px-6 py-3">Başlık</th>
                        <th scope="col" class="px-4 sm:px-6 py-3">Açıklama</th>
                        <th scope="col" class="px-4 sm:px-6 py-3">Gönderen</th>
                        <th scope="col" class="px-4 sm:px-6 py-3 rounded-tr-lg">Ek</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(report, index) in dummyReports" :key="report.id"
                        :class="index % 2 === 0 ? 'bg-slate-200' : 'bg-slate-300'" class="border-b border-slate-100">
                        <td class="px-4 sm:px-6 py-4 flex flex-col">
                            <div class="flex flex-col justify-around gap-2">
                                <div class="flex gap-4 border-b-2 border-slate-4k00">
                                    <span class="font-bold min-w-16 text-nowrap">Kitap: </span>
                                    <span>Kitap Adı</span>
                                </div>
                                <div class="flex gap-4 border-b-2 border-slate-400">
                                    <span class="font-bold min-w-16">Bölüm: </span>
                                    <span>Bölüm
                                        Adı Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
                                </div>
                                <div class="flex gap-4 border-b-2 border-slate-400">
                                    <span class="font-bold min-w-16">Test: </span>
                                    <span>Test Adı</span>
                                </div>
                                <div class="flex gap-4 border-b-2 border-slate-400">
                                    <span class="font-bold min-w-16">Soru: </span>
                                    <span>Soru Numarası</span>
                                </div>
                            </div>
                        </td>
                        <td class="px-4 sm:px-6 py-4">{{ report.title }}</td>
                        <td class="px-4 sm:px-6 py-4">{{ report.description }}</td>
                        <td class="px-4 sm:px-6 py-4">{{ report.created_by.name }}</td>
                        <td class="px-4 sm:px-6 py-4">
                            <button @click="openModal(report.attachment)"
                                class="text-blue-500 flex items-center gap-2 font-medium">
                                <img src="@/assets/image-icon.svg" class="w-8 h-8 sm:w-6 sm:h-6 min-w-6 min-h-6" />
                                <span class="hidden sm:inline">Görüntüle</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Modal :isVisible="isModalVisible" :imageSrc="modalImageSrc" @close="isModalVisible = false" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import Modal from './Modal.vue';

const isModalVisible = ref(false);
const modalImageSrc = ref('');

const openModal = (src) => {
    modalImageSrc.value = src;
    isModalVisible.value = true;
};

// Dummy veriler
const dummyReports = ref([
    { id: 1, title: 'Hata 1', description: 'Açıklama 1', created_by: { name: 'Kullanıcı 1' }, attachment: 'https://via.placeholder.com/150' },
    { id: 2, title: 'Hata 2', description: 'Açıklama 2', created_by: { name: 'Kullanıcı 2' }, attachment: 'https://via.placeholder.com/150' },
    { id: 3, title: 'Hata 3', description: 'Açıklama 3', created_by: { name: 'Kullanıcı 3' }, attachment: 'https://via.placeholder.com/150' },
    { id: 4, title: 'Hata 4', description: 'Açıklama 4', created_by: { name: 'Kullanıcı 4' }, attachment: 'https://via.placeholder.com/150' },
    { id: 5, title: 'Hata 5', description: 'Açıklama 5', created_by: { name: 'Kullanıcı 5' }, attachment: 'https://via.placeholder.com/150' },
    { id: 6, title: 'Hata 6', description: 'Açıklama 6', created_by: { name: 'Kullanıcı 6' }, attachment: 'https://via.placeholder.com/150' },
    { id: 7, title: 'Hata 7', description: 'Açıklama 7', created_by: { name: 'Kullanıcı 7' }, attachment: 'https://via.placeholder.com/150' },
    { id: 8, title: 'Hata 8', description: 'Açıklama 8', created_by: { name: 'Kullanıcı 8' }, attachment: 'https://via.placeholder.com/150' },
    { id: 9, title: 'Hata 9', description: 'Açıklama 9', created_by: { name: 'Kullanıcı 9' }, attachment: 'https://via.placeholder.com/150' },
    { id: 10, title: 'Hata 10', description: 'Açıklama 10', created_by: { name: 'Kullanıcı 10' }, attachment: 'https://via.placeholder.com/150' },
    { id: 11, title: 'Hata 11', description: 'Açıklama 11', created_by: { name: 'Kullanıcı 11' }, attachment: 'https://via.placeholder.com/150' },
    { id: 12, title: 'Hata 12', description: 'Açıklama 12', created_by: { name: 'Kullanıcı 12' }, attachment: 'https://via.placeholder.com/150' },
    { id: 13, title: 'Hata 13', description: 'Açıklama 13', created_by: { name: 'Kullanıcı 13' }, attachment: 'https://via.placeholder.com/150' },
    { id: 14, title: 'Hata 14', description: 'Açıklama 14', created_by: { name: 'Kullanıcı 14' }, attachment: 'https://via.placeholder.com/150' },
    { id: 15, title: 'Hata 15', description: 'Açıklama 15', created_by: { name: 'Kullanıcı 15' }, attachment: 'https://via.placeholder.com/150' },
    { id: 16, title: 'Hata 16', description: 'Açıklama 16', created_by: { name: 'Kullanıcı 16' }, attachment: 'https://via.placeholder.com/150' },
    { id: 17, title: 'Hata 17', description: 'Açıklama 17', created_by: { name: 'Kullanıcı 17' }, attachment: 'https://via.placeholder.com/150' },
    { id: 18, title: 'Hata 18', description: 'Açıklama 18', created_by: { name: 'Kullanıcı 18' }, attachment: 'https://via.placeholder.com/150' },
    { id: 19, title: 'Hata 19', description: 'Açıklama 19', created_by: { name: 'Kullanıcı 19' }, attachment: 'https://via.placeholder.com/150' },
    { id: 20, title: 'Hata 20', description: 'Açıklama 20', created_by: { name: 'Kullanıcı 20' }, attachment: 'https://via.placeholder.com/150' },
]);
</script>

<style lang="scss" scoped></style>