<template>
  <header>
    <nav class="bg-slate-900 border-b border-gray-200 px-4 lg:px-6 py-2.5 shadow lg:h-[80px] flex">
      <div class="w-full mx-auto max-w-screen-lg flex items-center justify-between">
        <a href="/" class="">
          <img :src="logoUrl" class="h-6 sm:h-9 md:ml-4" alt="indiviBook Logo" />
        </a>
        <span class="lg:text-xl text-md font-semibold whitespace-nowrap text-slate-100 lg:mr-4 mr-5">Video Çözüm</span>
        <div v-if="isAuthenticated" class="lg:absolute lg:top-5 lg:right-16">
          <LogOutButton />
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import LogOutButton from './LogOutButton.vue';

const store = useStore();
const isAuthenticated = computed(() => store.getters.isAuthenticated);

const logoUrl = process.env.VUE_APP_COMPANY_LOGO_PATH;
</script>

<style lang="scss" scoped></style>
