<template>
  <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white rounded-md shadow-lg w-1/2">
      <div class="flex justify-between items-center bg-slate-900 py-2 px-4 rounded-t">
        <h2 class="text-2xl font-semibold text-white">Ek Resim</h2>
        <button @click="close" class="h-8 w-8 bg-red-500 text-white px-2 font-bold rounded-md hover:bg-red-600">
          X
        </button>
      </div>
      <div class="p-4 flex items-center justify-center">
        <img :src="imageSrc" alt="Attachment" class="max-w-full h-auto" />
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  imageSrc: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["close"]);

const close = () => {
  emits("close");
};
</script>

<style scoped></style>
