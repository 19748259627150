<template>
  <footer
    class="bg-gray-900 border-t border-gray-200 px-4 lg:px-6 py-2.5 shadow-inner"
  >
    <div
      class="w-full mx-auto max-w-screen-lg flex md:items-center md:justify-between"
    >
      <span class="text-xs md:text-sm text-slate-100 sm:text-center ml-4"
        >© {{ new Date().getFullYear() }} |
        <a href="https://indivibook.com" class="hover:underline">indiviBook </a>
        by
        <a href="https://indivi.tech" class="hover:underline">indiviTech.</a>
        All Rights Reserved.
      </span>
      <img :src="logoUrl" class="self-center mr-4 h-6 sm:h-9" />
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      logoUrl: process.env.VUE_APP_FOOTER_LOGO_PATH,
    };
  },
};
</script>

<style lang="scss" scoped></style>
