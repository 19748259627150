<template>
    <div class="flex flex-col xl:flex-row gap-2 pb-8">
        <div class="xl:w-1/2 flex flex-col justify-around">
            <div class="flex gap-4">
                <span class="font-bold min-w-16">Kitap: </span>
                <span>{{ bookTitle }}</span>
            </div>
            <div class="flex gap-4">
                <span class="font-bold min-w-16">Bölüm: </span>
                <span>{{ sectionTitle }}</span>
            </div>
            <div class="flex gap-4">
                <span class="font-bold min-w-16">Test: </span>
                <span>{{ testTitle }}</span>
            </div>
            <div class="flex gap-4">
                <span class="font-bold min-w-16">Soru: </span>
                <span>{{ questionTitle }}</span>
            </div>
        </div>
        <div v-if="questionImage" class="xl:w-1/2">
            <img :src="questionImage" alt="Question Image" class="max-w-full h-auto mt-4 rounded-lg" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ReportFormHeader",
    computed: {
        ...mapGetters([
            "currentBookTitle",
            "currentSectionTitle",
            "currentTestTitle",
            "currentQuestionTitle",
            "currentQuestionImage"
        ]),
        bookTitle() {
            return this.currentBookTitle;
        },
        sectionTitle() {
            return this.currentSectionTitle;
        },
        testTitle() {
            return this.currentTestTitle;
        },
        questionTitle() {
            return this.currentQuestionTitle;
        },
        questionImage() {
            return this.currentQuestionImage;
        }
    },
};
</script>

<style scoped>
img {
    max-width: 100%;
    height: auto;
    margin-top: 1rem;
}
</style>
