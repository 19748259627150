import Validations from "./Validations";

export default class LoginValidations {
  constructor(email, password) {
    this.email = email;
    this.password = password;
  }

  checkValidations() {
    let errors = {};

    if (!Validations.checkEmail(this.email)) {
      errors.email = "Geçersiz e-posta adresi girdiniz";
    }

    if (!Validations.minLength(this.password, 6)) {
      errors.password = "Yanlış veya eksik şifre girdiniz";
    }
    return errors;
  }
}
