<template>
  <div
    class="fixed bottom-24 left-48 w-96 h-96 bg-[#84FFC9] mix-blend-multiply rounded-full blur-3xl opacity-30 animate-blob animation-delay-4000 z-30">
  </div>
  <div
    class="fixed top-52 left-1/3 w-96 h-96 bg-[#AAB2FF] mix-blend-multiply rounded-full blur-3xl opacity-30 animate-blob animation-delay-2000 z-20">
  </div>
  <div
    class="fixed top-48 right-48 w-96 h-96 bg-[#ECA0FF] mix-blend-multiply rounded-full blur-3xl opacity-30 animate-blob z-10">
  </div>
</template>

<script setup></script>
