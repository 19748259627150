<template>
  <div class="relative overflow-x-auto mb-8 rounded-lg container sm:mx-0 sm:px-0 mx-auto px-4">
    <table class="w-full text-sm text-left text-gray-500 rounded-lg">
      <thead class="text-xs text-gray-400 uppercase bg-slate-900 rounded-t-lg">
        <tr>
          <th scope="col" class="px-2 sm:px-6 py-3 rounded-tl-lg">Başlık</th>
          <th scope="col" class="px-2 sm:px-6 py-3">Açıklama</th>
          <th scope="col" class="px-2 sm:px-6 py-3">Gönderen</th>
          <th scope="col" class="px-2 sm:px-6 py-3 rounded-tr-lg">Ek</th>
        </tr>
      </thead>
      <tbody class="rounded-b-lg">
        <tr v-for="report in reports" :key="report.id" class="bg-slate-200 border-b border-slate-100">
          <td class="px-2 sm:px-6 py-4">{{ report.title }}</td>
          <td class="px-2 sm:px-6 py-4">{{ report.description }}</td>
          <td class="px-2 sm:px-6 py-4">{{ report.created_by.name }}</td>
          <td class="px-2 sm:px-6 py-4">
            <button @click="openModal(report.attachment)" class="text-blue-500 flex items-center gap-2 font-medium">
              <img src="@/assets/image-icon.svg" class="w-8 h-8 sm:w-6 sm:h-6" />
              <span class="hidden sm:inline">Görüntüle</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <Modal :isVisible="isModalVisible" :imageSrc="modalImageSrc" @close="isModalVisible = false" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import Modal from "./Modal.vue";

defineProps({
  reports: {
    type: Array,
    required: true,
  },
});

const isModalVisible = ref(false);
const modalImageSrc = ref("");

const openModal = (src) => {
  modalImageSrc.value = src;
  isModalVisible.value = true;
};
</script>

<style lang="scss" scoped></style>
