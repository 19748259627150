<template>
  <button @click="onLogout"
    class="py-2 px-4 bg-slate-900 hover:bg-slate-800 rounded-md text-white text-nowrap text-sm inline-flex gap-2">
    Çıkış yap
    <img src="@/assets/logout-icon.svg" /> </button>
</template>

<script>
import { mapActions } from "vuex";
import { useRouter } from "vue-router";

export default {
  methods: {
    ...mapActions(["logout"]),
    onLogout() {
      this.logout();
      this.$router.push({ name: "Login" });
    },
  },
  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>

<style scoped></style>
